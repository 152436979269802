import { Form } from '@remix-run/react';

import { type LoaderFunctionArgs } from '@remix-run/node';
import {
  ContinueWithGoogleButton,
  ContinueWithMicrosoftButton,
  FeatureItem,
  ShorebirdLogo,
} from '~/components';
import { authenticator } from '~/services/auth.server';

export const loader = async ({ request }: LoaderFunctionArgs) => {
  return authenticator.isAuthenticated(request, {
    successRedirect: '/',
  });
};

export default function Login() {
  const features = [
    'Integrate in minutes',
    'Push updates to any Dart code',
    'Supports Android & iOS',
  ];

  return (
    <div className="mx-auto sm:mt-20 w-10/12 max-w-xl">
      <div>
        <ShorebirdLogo />
      </div>
      <h3 className="mt-3 text-4xl font-bold">Deploy Updates Instantly</h3>
      <p className="mt-3">
        Code push is a cloud service that allows developers to push app updates
        directly to users&apos; devices.
      </p>

      <div className="divider"></div>

      <ul>
        {features.map((feature) => (
          <FeatureItem key={feature} description={feature} />
        ))}
      </ul>

      <div className="h-8"></div>

      <Form action="/auth/google" method="post">
        <ContinueWithGoogleButton />
      </Form>

      <Form action="/auth/microsoft" method="post">
        <ContinueWithMicrosoftButton />
      </Form>
    </div>
  );
}
